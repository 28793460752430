import React, { useEffect } from 'react';
import Marquee from "react-fast-marquee";
import { TypeAnimation } from "react-type-animation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Empty } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./style.scss"
import sol from'../../images/babe/solana-summer-hero-solana-sun.svg'
declare const window: Window & typeof globalThis & { Jupiter: any; opera: any };

const HomePage = () => {

    useEffect(() => {
        if (window.Jupiter) {
            window.Jupiter.init({
                displayMode: 'integrated',
                integratedTargetId: 'integrated-terminal',
                endpoint: 'https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30',
                formProps: {
                    fixedOutputMint: true,
                    initialOutputMint: "4dF72nSaTE9igxSzF6tmCW3N33X7ZHnZxAkZ4YQ1pump",
                },
            });
        }
    }, []);

    AOS.init({
        duration: 1000, // Animation duration in milliseconds
        easing: 'ease-in-out', // Easing function for animations
        once: true, // Whether animation should happen only once
        mirror: false, // Whether elements should animate out while scrolling past them
    });

    return (
        <>
            <header className="header">
                <div className="container-row">
                    <div className="main-menu">
                        <ul className="list-menu">
                            <li>
                                <a href="#about">About Babe Pig</a>
                            </li>
                            <li>
                                <a href="#babe">$BABE</a>
                            </li>
                            <li>
                                <a href="#buy">Buy $BABE</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <section className="section-about" id="about" >
                <div className="wrap-about">
                    <div className="box-img">
                        <img src="../images/babe/video-ezgif.com-video-to-gif-converter.gif" alt="" />
                    </div>
                    <div className="title">
                        <TypeAnimation
                            style={{ whiteSpace: 'pre-line', height: '195px', display: 'block', fontFamily:'FreelokaBold', color:'pink' }}
                            sequence={[
                                "Babe: ",
                                1000,
                                `Babe: Pig`,
                                1000,
                                "Babe: Pig in ",
                                1000,
                                `Babe: Pig in the `,
                                1000,
                                `Babe: Pig in the City `,
                                1000,
                            ]}
                            wrapper="span"
                            speed={30}
                            repeat={Infinity}
                        />
                    </div>
                    <div className="list-social" data-aos="zoom-in" data-aos-duration="1000">
                        <div className="item">
                            <a href="https://x.com/babepump" target='blank'>
                                <img src="../images/s-1.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://t.me/babe_solana" target='blank'><img src="../images/s-2.png" alt="" /></a>
                        </div>
                        {/* <div className="item">
                        <a href="https://dexscreener.com/solana/bdj4hq8h2djdylnytfj3xdtsnk1w8qhgbrqitl1k1w1p" target='blank'>
                        <img src="../images/s-3.png" alt="" /></a>
                            
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="section-pool-info">
                <div className="container-row">
                    <div className="wrap-pool-info" data-aos="fade-up" data-aos-duration="2000">
                        <div className="title">
                            CONTACT ADDRESS
                        </div>
                        <div className="row-contract">
                            <p>4dF72nSaTE9igxSzF6tmCW3N33X7ZHnZxAkZ4YQ1pump</p>
                            <div className="icon">
                                {/* <button className="btn-copy">
                                    <img src="../images/copy.png" alt="" />
                                </button> */}
                                <CopyToClipboard
                                    text='4dF72nSaTE9igxSzF6tmCW3N33X7ZHnZxAkZ4YQ1pump'
                                    onCopy={() => {
                                        message.success({
                                            type: "success",
                                            content: `Copied success`,
                                            className: "custom-class",
                                            duration: 2,
                                        });
                                    }}
                                >
                                    <button className="btn-copy">
                                        <img src="../images/copy.png" alt="" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="row-buy-ape">
                            <button className="btn-buy" 
                            // onClick={() => window.open("https://raydium.io/swap/?inputMint=sol&outputMint=HiYHJ6kAzwo2Z3hbop7Eqs6UB8BP7n6ujAx7iM7Hpump", "_blank")}
                            >
                                Buy $BABE
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-marquue">
                <Marquee>
                    <div className="list-marquee">
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>

                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                        <div className="item text-hehe">
                             $BABE
                        </div>
                    </div>
                </Marquee>
            </section>
            <section className="section-human-word" id="babe">
                <div className="container-row">
                    <div className="wrap-human">
                        {/* <div className="box-img">
                            <img src="../images/aaa.gif" alt="" />
                            <img src="../images/aaa.gif" alt="" />

                        </div> */}
                        <div className="guide-human" data-aos="slide-up" data-aos-duration="1000">
                            <div className="title">
                                About BABE
                            </div>
                            <div className="desc">
                                
                                <span>  "Babe" star James Cromwell poses for a photo with the pig he saved from slaughter. The actor has named the pig Babe: “Every pig deserves to live in peace and joy at a sanctuary."</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-swap">
                <div className="container-row">
                    <div className="wrap-swap" data-aos="slide-up" data-aos-duration="2000">
                        <div className="title">
                            <div style={{color:'pink'}}>Power By</div>
                            <img src={sol} alt=''/>
                            <div>Solana</div>
                        </div>
                        <div className="box-wrap-swap">
                            <div id="integrated-terminal" style={{ width: '400px' }}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-buy" id="buy">
                <div className="container-row">
                    <div className="wrap-buy">
                        <div className="title" data-aos="slide-up" data-aos-duration="2000">
                            How to buy?
                        </div>
                        <div className="group-buy">
                            <div className="item" data-aos="slide-up" data-aos-duration="2200">
                                <div className="icon-buy">
                                    <img src="../images/b-1.png" alt="" />
                                </div>
                                <div className="text-buy">
                                    <div className="txt">
                                        Create a Wallet
                                    </div>
                                    <div className="desc">
                                        Download and install phantom on the appstore, or, alternatively if you’re on desktop download and install the browser extension
                                    </div>
                                    <button className="btn-get">
                                        <a href="https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" target='blank'>
                                            Get Phantom
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="item" data-aos="slide-up" data-aos-duration="2400">
                                <div className="icon-buy">
                                    <img src="../images/b-2.png" alt="" />
                                </div>
                                <div className="text-buy">
                                    <div className="txt">
                                        Secure your SOL
                                    </div>
                                    <div className="desc">
                                        Now all you’ve got to do is buy your SOL in the Phantom App, or, alternatively you can use an exchange and deposit using your wallet address
                                    </div>
                                </div>
                            </div>
                            <div className="item" data-aos="slide-up" data-aos-duration="2600">
                                <div className="icon-buy">
                                <img src="../images/babe/video-ezgif.com-video-to-gif-converter.gif" alt="" />
                                </div>
                                <div className="text-buy">
                                    <div className="txt">
                                        Buy $BABE
                                    </div>
                                    <div className="desc">
                                        Now go to Raydium and paste the $BABE contract address to swap your SOL
                                    </div>
                                </div>
                            </div>
                            <div className="item" data-aos="slide-up" data-aos-duration="2800">
                                <div className="icon-buy">
                                    <img src="../images/b-1.png" alt="" />
                                </div>
                                <div className="text-buy">
                                    <div className="txt">
                                        Add to your wallet
                                    </div>
                                    <div className="desc">
                                        Now you’re all set! Welcome aboard the next rocket to the moon, just import the contract address to view you’re holdings and you can track your earnings.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section-tokenomic">
                <div className="m-images">
                    <img src="../images/m-2.png" alt="" />
                </div>
                <div className="m-images-s">
                    <img src="../images/m-1.png" alt="" />
                </div>
                <div className="container-row">
                    <div className="wrap-tokenomic" id="chart" data-aos="slide-up" data-aos-duration="2800">
                        <div className="title">
                            Tokenomics
                        </div>
                        <div className="list-info-burn">
                            <div className="item">
                                <div className="box-img">
                                    <img src="../images/q-1.png" alt="" />
                                </div>
                                <div className="text">
                                    LP  BURNT
                                </div>
                            </div>
                            <div className="item">
                                <div className="box-img">
                                    <img src="../images/q-2.png" alt="" />
                                </div>
                                <div className="text">
                                    MINT REVOKED
                                </div>
                            </div>
                            <div className="item">
                                <div className="box-img">
                                    <img src="../images/q-3.png" alt="" />
                                </div>
                                <div className="text">
                                    0% TAX
                                </div>
                            </div>
                        </div>
                        <div className="address-sc">
                            <div className="row-contract">
                                <p>
                                    9GF36LpYdZpCXSiekcYZPU54oeVhr9B3NGgbdg8QLKqX
                                </p>
                                <div className="icon">
                                    <CopyToClipboard
                                        text={`9GF36LpYdZpCXSiekcYZPU54oeVhr9B3NGgbdg8QLKqX`}
                                        onCopy={() => {
                                            message.success({
                                                type: "success",
                                                content: `Copied success`,
                                                className: "custom-class",
                                                duration: 2,
                                            });
                                        }}
                                    >
                                        <button className="btn-copy">
                                            <img src="../images/copy-1.png" alt="" />
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div className="row-buy-ape">
                                <button className="btn-buy" onClick={() => window.open("https://dexscreener.com/solana/3tetpnxnjiq7g1fjnafvadaze5vp7nvxa5wnaonugnb2", "_blank")}>
                                    Buy $HEHE
                                </button>
                            </div>
                        </div>
                        <div className="title res">
                            Toltal Supply:
                        </div>
                        <div className="number-total">
                            88,888,888,888 HEHE
                        </div>
                        <div className="number-total">
                            HEHE made 88,888,888,888 tokens but he's a greedy son of a bitch and wants to keep them all to himself
                        </div>
                        <div className="title mar-b-60">
                            100% Burner LP.
                        </div>
                        <div className="title">
                            Follow Us On Social Media
                        </div>
                        <div className="list-social">
                            <div className="item">
                                <a href="https://x.com/hehesolana_xyz?s=21" target='blank'>
                                    <img src="../images/s-1.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://t.me/HEHE_solana" target='blank'><img src="../images/s-2.png" alt="" /></a>
                            </div>
                            <div className="item">
                                <img src="../images/s-3.png" alt="" />
                            </div>
                            <div className="item">
                                <a href="https://www.tiktok.com/@hehesolana" target='blank'>
                                    <img src="../images/s-21.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://www.youtube.com/@hehesolana" target='blank'>
                                    <img src="../images/s-22.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="info-ape">
                            <div className="txt-name">
                                $HEHE
                            </div>
                            <div className="desc-name">
                                {/* It’s just a <span>The HEHE of Wall Street</span> 
                                Made in <span>Vietnam 🇻🇳</span>
                            </div>
                            <div className="row-buy-ape">
                                <button className="btn-buy" onClick={() => window.open("https://dexscreener.com/solana/3tetpnxnjiq7g1fjnafvadaze5vp7nvxa5wnaonugnb2", "_blank")}>
                                    Buy $HEHE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <footer className="footer">
                <div className="container-row">
                    <div className="wrap-footer">
                        <div className="list-social">
                            <div className="item">
                                <a href="https://x.com/babepump" target='blank'>
                                    <img src="../images/s-1.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://t.me/babe_solana" target='blank'><img src="../images/s-2.png" alt="" /></a>
                            </div>
                        </div>

                        <div className="copy-right">
                            © 2024 $BABE - ALL RIGHTS RESERVED
                        </div>
                    </div>
                </div>
                <div className="box-img">
                    <img src="../images/babe/video-ezgif.com-video-to-gif-converter.gif" alt="" />
                </div>
            </footer>
        </>
    )
}
export default HomePage

